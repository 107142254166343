import formatNumber from 'src/utils/formatNumber'

const donationAmount = order => {
  const amount = order.cover_fees ? order.amount_entered : order.amount
  const displayAmount = formatNumber(amount)
  return (order.currency.symbol || '')
  .concat(order.crypto ? displayAmount : displayAmount.split('.')[0])
}

export default donationAmount
